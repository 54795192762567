/* eslint-disable camelcase */
export default class Category {
  alias;
  description;
  library_id;
  name;
  type;

  constructor({type}) {
    this.alias = "";
    this.description = "";
    this.library_id = null;
    this.name = "";
    this.type = type;
  }
}